import {
  MappingProfile,
  createMap,
  forMember,
  mapFrom
} from "@automapper/core";
import { ContactDto } from "../contact/contact.dto";
import { Contact } from "../contact/contact.model";
import { LocationDto } from "./location.dto";
import { Location } from "./location.model";

export const locationProfile: MappingProfile = (mapper) => {
  createMap<LocationDto, Location>(
    mapper,
    "LocationDto",
    "Location",
    forMember(
      (dest) => dest.id,
      mapFrom((src) => src.id),
    ),
    forMember(
      (dest) => dest.address,
      mapFrom((src) => src.address),
    ),
    forMember(
      (dest) => dest.commune,
      mapFrom((src) => src.commune),
    ),
    forMember(
      (dest) => dest.postalCode,
      mapFrom((src) => src.postal_code),
    ),
    forMember(
      (dest) => dest.province,
      mapFrom((src) => src.province),
    ),
    forMember(
      (dest) => dest.position,
      mapFrom((src) => [src.latitude ?? 0, src.longitude ?? 0, undefined]),
    ),
    forMember(
      (dest) => dest.contacts,
      mapFrom((src) => src.contacts ? mapper.mapArray<ContactDto, Contact>(src.contacts, "ContactDto", "Contact") : []),
    ),
  )
}

