import { MappingProfile, createMap, forMember, mapFrom } from "@automapper/core"
import { BarReviewRequestDto } from "./bar-review.dto"
import { BarReviewRequest } from "./bar-review.model"

export const barReviewRequestProfile: MappingProfile = (mapper) => {
  createMap<BarReviewRequest, BarReviewRequestDto>(
    mapper,
    "BarReviewRequest",
    "BarReviewRequestDto",
    forMember(
      (dest) => dest.phoneNumber,
      mapFrom((src) => String(src.phoneNumber ?? "")),
    ),
    forMember(
      (dest) => dest.email,
      mapFrom((src) => src.email),
    ),
    forMember(
      (dest) => dest.fullName,
      mapFrom((src) => src.fullName),
    ),
    forMember(
      (dest) => dest.locale,
      mapFrom((src) => src.locale),
    ),
    forMember(
      (dest) => dest.message,
      mapFrom((src) => src.message),
    ),
  )

}
